import React from 'react';
import { Link } from 'gatsby';
import TitleHeader from '../components/TitleHeader';
import SiteData from './SiteData';

import Jedzenie from '../images/blog/jak_jedzenie_wpływa.jpg'
import Licowki from '../images/blog/licowki.png'
import Odbudowa from '../images/blog/odbudowa_zebow_w_jeden_dzien.jpg'
import Przebarwiony from '../images/blog/przebarwiony_zab.webp'


const Blog = (props) => {
	const { currentLang} = SiteData(props);
	// console.log("Team", props)
	// console.log("Team", languages, currentLang)
	return (
		<div>
			<TitleHeader title="Blog" />
			<div className="content">
				<Link to={`${currentLang}/blog/all-on-4`}>
					<div className="blog">
						<img src={Odbudowa} className="blog--img" alt="zdjecie"/>
						<div className="blog--text">
							<h2>Odbudowa zębów w jeden dzień – All on 4</h2>
							<p>
								Czy ruszająca się proteza dolna przy jedzeniu lub dyskomfort, że wypadnie przy bardziej
								energicznym uśmiechu albo grymasie nie są Ci obce?...
							</p>
						</div>
					</div>
				</Link>
				<Link to={`${currentLang}/blog/impact-food`}>
					<div className="blog">
						<img src={Jedzenie} className="blog--img" alt="zdjecie"/>
						<div className="blog--text">
							<h2>Jak jedzenie wpływa na kondycję naszych zębów.</h2>
							<p>
								Każdy z nas chciałby, aby jego zęby były zdrowe a wizyty u dentysty wiązały się jedynie
								z regularnym przeglądem oraz profesjonalnymi zabiegami higienizacyjnymi. Najbardziej
								powszechnymi problemami zdrowotnymi w jamie ustnej są próchnica, stany zapalne dziąseł,
								kwasowa erozja szkliwa oraz urazy. Wszystkie te zmiany prowadzą do defektów
								estetycznych, ale również nadwrażliwości, bólu a w konsekwencji nawet do ewentualnej
								utraty zębów. Większości tych problemów można uniknąć stosując właściwe zabiegi
								higieniczne w jamie ustnej i dbając o odpowiednią dietę. Co zatem jeść, a czego unikać
								by Twoje zęby były piękne i zdrowe?...
							</p>
						</div>
					</div>
				</Link>
				<Link to={`${currentLang}/blog/licowki`}>
					<div className="blog">
						<img src={Licowki} className="blog--img" alt="zdjecie"/>
						<div className="blog--text">
							<h2>Licówki – sposób na piękne zęby – jak wygląda proces ich zmiany?</h2>
							<p>
								Marzysz o idealnym uśmiechu? Twoje zęby są przebarwione, występują pomiędzy nimi szpary
								lub maja pewne nierówności? Może po prostu chcesz mieć gwiazdorski uśmiech? Problemy te
								można rozwiązać licówkami porcelanowymi, które sprawią, że Twój uśmiech będzie lśnił.
							</p>
						</div>
					</div>
				</Link>
				<Link to={`${currentLang}/blog/discolored-tooth`}>
					<div className="blog">
						<img src={Przebarwiony} className="blog--img" alt="zdjecie"/>
						<div className="blog--text">
							<h2>Przebarwiony ząb po leczeniu kanałowym – czy można coś z tym zrobić?</h2>
							<p>
								Masz ząb lub zęby które są przebarwione, różnią się kolorem od reszty (są
								brunatno-szare) i być może były w przeszłości leczone kanałowo? Może taki ząb to górna
								jedynka lub dwójka i żadne zdjęcie nie może się udać bez wyraźnie widocznej różnicy w
								uśmiechu?
							</p>
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};

export default Blog;
