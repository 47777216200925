import React from 'react';
import Blog from '../components/Blog';
import Layout from '../layout/pl';

const BlogComp = (props) => {
	// console.log("blog", props)
	// console.log("blog", props)
	return (
		<Layout {...props}>
			<Blog {...props.location}/>
			{/* <Blog /> */}
		</Layout>
	);
};

export default BlogComp;
